import { render, staticRenderFns } from "./Wait-Ticket.vue?vue&type=template&id=33801eb1&scoped=true&"
import script from "./Wait-Ticket.vue?vue&type=script&lang=js&"
export * from "./Wait-Ticket.vue?vue&type=script&lang=js&"
import style0 from "./Wait-Ticket.vue?vue&type=style&index=0&id=33801eb1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33801eb1",
  null
  
)

export default component.exports